

















































































































import Vue from "vue";
import Component from "vue-class-component";
import { MdContent, MdRipple, MdTable } from "vue-material/dist/components";

Vue.use(MdContent);
Vue.use(MdRipple);
Vue.use(MdTable);

const TechProps = Vue.extend({
  props: {
    propMessage: String,
  },
  methods: {
    yearsExperience: function(startYear: number) {
      return new Date().getFullYear() - startYear;
    },
  },
});

@Component({
  name: "Tech",
  components: {},
})
export default class Tech extends TechProps {}
