
























































































































import Vue from "vue";
import Component from "vue-class-component";
import { MdIcon, MdTabs, MdMenu, MdList } from "vue-material/dist/components";

Vue.use(MdTabs);
Vue.use(MdIcon);

const NavbarProps = Vue.extend({
  props: {
    propMessage: String,
  },
  methods: {
    // since md-tabs doesn't natively support opening a link in a new window/tab
    newWindow: function(url: string) {
      window.open(url, "_blank");
    },
  },
});

@Component({
  components: {},
})
export default class Navbar extends NavbarProps {}
