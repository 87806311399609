





































import Vue from "vue";
import Component from "vue-class-component";
import Thought from "@/components/Thought.vue";
import { MdDivider, MdList } from "vue-material/dist/components";

Vue.use(MdDivider);
Vue.use(MdList);

const ThoughtsProps = Vue.extend({
  props: {},
  methods: {},
  components: {
    Thought,
  },
});

@Component({
  components: {},
})
export default class Thoughts extends ThoughtsProps {}
