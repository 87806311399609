



















import Vue from "vue";
import Component from "vue-class-component";
import Navbar from "@/components/Navbar.vue";
import Sidenav from "@/components/Sidenav.vue";

const NavProps = Vue.extend({
  props: {},
  methods: {},
  components: {
    Navbar,
    Sidenav,
  },
});

@Component({
  components: {},
})
export default class Nav extends NavProps {}
