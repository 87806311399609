
























































































































































import Vue from "vue";
import Component from "vue-class-component";
import {
  MdButton,
  MdDrawer,
  MdIcon,
  MdList,
  MdToolbar,
} from "vue-material/dist/components";

Vue.use(MdButton);
Vue.use(MdDrawer);
Vue.use(MdIcon);
Vue.use(MdList);
Vue.use(MdToolbar);

const NavbarProps = Vue.extend({
  props: {
    propMessage: String,
  },
  data: () => ({
    menuVisible: false,
  }),
  methods: {
    // since md-tabs doesn't natively support opening a link in a new window/tab
    newWindow: function(url: string) {
      window.open(url, "_blank");
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
});

@Component({
  components: {},
})
export default class Navbar extends NavbarProps {}
