






















import Subdomain from "@/components/Subdomain.vue";
import Tech from "@/components/Tech.vue";

export default {
  name: "home",
  components: {
    Subdomain,
    Tech,
  },
};
