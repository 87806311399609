


























import Vue from "vue";
import Component from "vue-class-component";
import { MdButton, MdCard } from "vue-material/dist/components";
import { mapState, mapMutations } from "vuex";

Vue.use(MdButton);
Vue.use(MdCard);

const SubdomainProps = Vue.extend({
  props: {
    propMessage: String,
    destination: String,
    imagefile: String,
    name: String,
  },
});

@Component({
  components: {},
  computed: mapState(["count"]),
  methods: mapMutations(["increment"]),
})
export default class Subdomain extends SubdomainProps {
  // inital data

  // annotate refs type
  $refs!: {
    subdomainComponent: Subdomain;
  };

  // lifecycle hook
  mounted() {}
}
