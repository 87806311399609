























import Vue from "vue";
import { MdLayout } from "vue-material/dist/components";
import Subdomain from "@/components/Subdomain.vue";

Vue.use(MdLayout);

export default {
  name: "family",
  components: {
    Subdomain,
  },
};
