







































import Vue from "vue";
import Component from "vue-class-component";
import Nav from "@/views/Nav.vue";

const AppProps = Vue.extend({
  props: {},
  methods: {},
  components: {
    Nav,
  },
});

@Component({
  components: {},
})
export default class App extends AppProps {}
