







































import Vue from "vue";
import Component from "vue-class-component";
import {
  MdAvatar,
  MdButton,
  MdDialog,
  MdIcon,
  MdList,
} from "vue-material/dist/components";

Vue.use(MdAvatar);
Vue.use(MdButton);
Vue.use(MdDialog);
Vue.use(MdIcon);
Vue.use(MdList);

const ThoughtProps = Vue.extend({
  props: {
    title: String,
    subtitle: String,
    post: String,
    icon: String,
  },
  methods: {
    yearsExperience: function(startYear: number) {
      return new Date().getFullYear() - startYear;
    },
  },
  data: () => ({
    showDialog: false,
  }),
});

@Component({
  name: "Thought",
  components: {},
})
export default class Thought extends ThoughtProps {}
